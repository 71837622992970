<template>
    <div>
        <div class="container">
            <div v-if="config.etablissement.read_only_msg" class="row">
                <div class="col-md-12">
                    <div class="alert alert-info" v-html="config.etablissement.read_only_msg">

                    </div>
                </div>
            </div>
            <div v-show="config.etablissement.show_calendar">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card bg-light">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-6 mb-3 filter-wrapper">
                                        <transition name="fade">
                                            <div class="w-100" v-if="getDoctors" key="doctor-select">
                                                <label for="doctor" class="form-label">{{$t('global.docteur')}}</label>
                                                <v-select :clearable="false" id="doctor"  v-if="getDoctors" :options="getDoctorList" label="label" @input="onSelectDoctor" :value="getSelectedDoctor"></v-select>
                                            </div>
                                        </transition>
                                    </div>
                                    <div v-if="config.etablissement.office_filter" class="col-lg-6 mb-3">
                                        <transition name="fade">
                                            <div class="w-100" v-if="getOfficeList" key="show">
                                                <label for="office" class="form-label">{{$t('global.lieu')}}</label>
                                                <v-select :clearable="false" id="office"  v-if="getOfficeList" :options="getOfficeList" label="name" @input="onSelectOffice" :value="getSelectedOffice"></v-select>
                                            </div>
                                        </transition>
                                    </div>
                                    <div v-if="getReasons && getReasons.length > 0" class="col-lg-6 mb-3">
                                        <div class="w-100"  key="show">
                                            <label for="reason" class="form-label">{{$t('global.motif')}}</label>
                                            <v-select :clearable="false" id="reason" class="w-100"  v-if="getReasons" :options="filteredReasons" label="label" @input="onSelectReason" :value="getSelectedReason"></v-select>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 mb-3">
                                        <div class="w-100">
                                            <label for="date" class="form-label">{{$t('global.date')}}</label>
                                            <input type="date" id="date" :value="getDate.format('YYYY-MM-DD')" class="form-control" @input="onSelectDate">
                                        </div>
                                    </div>
                                </div>
                                <div class="row" v-if="getOffices && getOffices.length > 1">
                                    <div class="col-lg-6" v-for="office in getOffices">
                                        <div v-if="office.id !== 0" class="badge w-100 p-1" :style="'background-color:'+office.color">{{office.name}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <doctor-message></doctor-message>
                <transition name="fade"  mode="out-in">
                    <div class="row" >
                        <div class="col-md-12 mt-3">
                            <div class="position-relative">
                                <div id="spinner-wrapper">
                                    <transition name="fade"  mode="out-in">
                                        <div id="spinner" v-show="loading" class="spinner-border" role="status">
                                        </div>
                                    </transition>
                                </div>
                                <transition name="fade"  mode="out-in">
                                    <FullCalendar ref="fullCalendar" id="fullCalendar" :options="calendarOptions">
                                    </FullCalendar>
                                </transition>
                                <transition name="fade"  mode="out-in">
                                <div class="alert alert-info mt-2" v-if="slotNumber === 0 && getSelectedReason && !loading && getSelectedDoctor">
                                    <h5 class="text-center">
                                        <i class="bi bi-info-circle-fill mr-3"></i>
                                        <span>{{$t('global.aucune_plage_horaire_disponible')}}</span><br>
                                        <button class="btn btn-primary mt-3" @click="onSearchFreeSlot()">
                                            <span v-if="searchingDate" class="spinner-grow spinner-grow-sm text-white" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </span>
                                            <span v-else>
                                                <i class="bi bi-calendar-check"></i>
                                            </span>
                                            {{$t('global.trouver_plage_disponible')}}
                                        </button>
                                    </h5>
                                </div>
                                </transition>
                            </div>
                        </div>
                    </div>
                </transition>
                <transition name="fade">
                    <div class="row" v-show="!getSelectedDoctor">
                        <div class="col-md-12">
                            <div class="alert alert-info">
                                <h5 class="text-center">
                                    <i class="bi bi-info-circle-fill mr-3"></i>
                                    <span>{{$t('global.selectionner_docteur')}}</span>
                                </h5>
                            </div>
                        </div>
                    </div>
                </transition>
                <transition name="fade">
                    <div class="row" v-show="!getSelectedReason">
                        <div class="col-md-12">
                            <div class="alert alert-info">
                                <h5 class="text-center">
                                    <i class="bi bi-info-circle-fill mr-3"></i>
                                    <!--<span v-if="!getSelectedDoctor || !getSelectedOffice || getReasons.length === 0">Veuillez patienter...</span>-->
                                    <span>{{$t('global.selectionner_motif_rdv')}}</span>
                                </h5>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </div>
        <modal-confirmation></modal-confirmation>
        <modal-read-only></modal-read-only>
    </div>
</template>

<script>
    import FullCalendar from '@fullcalendar/vue'
    import {mapActions, mapGetters} from "vuex";
    import {namespaces} from "../../../store/namespaces";
    import calendarApi from '../../../api/calendar'
    import interactionPlugin from '@fullcalendar/interaction'
    import timeGridPlugin from '@fullcalendar/timegrid';
    import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
    import fr from '@fullcalendar/core/locales/fr';
    import en from '@fullcalendar/core/locales/en-gb';
    import pt from '@fullcalendar/core/locales/pt';
    import de from '@fullcalendar/core/locales/de';
    import ModalConfirmation from './modals/ModalConfirmation'
    import ModalReadOnly from "./modals/ModalReadOnly";
    import { EventBus, EventList } from '../../../event-bus.js';
    import moment from "moment";
    import DoctorMessage from "@/components/front/calendar/DoctorMessage.vue";
    export default {
        name: "Calendar",
        metaInfo() {
            let self = this
            return {
                title: 'Calendrier',
                meta: [{
                    name: 'description',
                    content: self.metaDescription
                }]
            }
        },
        components: {
            ModalReadOnly,
            FullCalendar,
            ModalConfirmation,
            DoctorMessage
        },
        data() {
            return {
                searchingDate: false,
                calendarApi: null,
                appointments: [],
                officeFilter: false,
                loading: false,
                loaded: false,
                config: Vue.$config,
                refreshInterval: null,
                slotNumber: null,
                calendarOptions: {
                    schedulerLicenseKey: Vue.$config.full_calendar_licence,
                    customButtons: {
                        weekButton:{
                            text: this.$t('global.semaine'),
                            click: ()=> {
                                if(this.getSelectedDoctor.id === 0){
                                    this.setSelectedDoctor(this.getDoctors.find(d=> true))
                                }
                                this.calendarApi.changeView('timeGridWeek')
                            }
                        },
                        dayButton: {
                            text: this.$t('global.jour'),
                            click: ()=> {
                                if(this.getSelectedDoctor.id === 0){
                                    this.setSelectedDoctor(this.getDoctors.find(d=> true))
                                }
                                this.calendarApi.changeView('timeGridDay')
                            }
                        },
                        doctorsButton: {
                            text: this.$t('global.docteurs'),
                            click: ()=> {
                                this.setSelectedDoctor(this.getDoctorList.find(d=> parseInt(d.id) === 0))
                                this.calendarApi.changeView('resourceTimeGridDay')
                            }
                        }
                    },
                    stickyHeaderDates: true,
                    eventOverlap: false,
                    headerToolbar: {
                        left: 'prev,next',
                        center: 'title',
                        //right: 'jour',
                        right: 'weekButton,dayButton,doctorsButton',
                    },
                    plugins: [ timeGridPlugin, resourceTimeGridPlugin , interactionPlugin ],
                    resources: [

                    ],
                    views: {
                        resourceTimeGridDay: {
                            type: 'resourceTimeGridDay',
                            //duration: { days: 4 },
                            buttonText: 'Docteurs'
                        }
                    },
                    initialView: 'resourceTimeGridDay',
                    locale: localStorage.getItem('locale') || fr,
                    locales: [fr, en, pt, de],
                    weekends: true,
                    height: 'auto',
                    allDaySlot: false,
                    slotDuration: '00:10:00',
                    slotLabelFormat: {
                        hour: '2-digit',
                        minute: '2-digit',
                        omitZeroMinute: false,
                        meridiem: false
                    },
                    displayEventTime: false,
                }
            }
        },
        computed: {
            ...mapGetters({
                getIsLoggedIn: namespaces.auth + 'getIsLoggedIn',
                getSlots: namespaces.calendar+'getSlots',
                getSelectedReason: namespaces.calendar+'getSelectedReason',
                getSelectedDoctor: namespaces.calendar+'getSelectedDoctor',
                getSelectedOffice: namespaces.calendar+'getSelectedOffice',
                getDate: namespaces.calendar+'getDate',
                getEndDate: namespaces.calendar+'getEndDate',
                getOffices: namespaces.calendar+'getOffices',
                getOfficeList: namespaces.calendar+'getOfficeList',
                getDoctors: namespaces.calendar+'getDoctors',
                getDoctorList: namespaces.calendar+'getDoctorList',
                getReasons: namespaces.calendar+'getReasons',
                getAppointments: namespaces.calendar+'getAppointments',
                getUser: namespaces.auth + 'getUser',
                getCurrentView: namespaces.calendar + 'getCurrentView',
                getResources: namespaces.calendar + 'getResources',
            }),
            filteredReasons() {
                if(this.getSelectedDoctor && this.getSelectedDoctor.id !== 0){
                    let doctor = this.getDoctors.find(d => d.id === this.getSelectedDoctor.id);
                    return doctor.reasons;
                }else {
                    let reason = []
                    this.getDoctors.forEach(d=> {
                        this.getReasons.forEach(r=> {
                            if(d.reasons.find(reason=> reason.id === r.id) && !reason.find(reason=> reason.id === r.id))reason.push(r)
                        })
                    })
                    return reason
                }
            },
            metaDescription() {
                let doctorString = ''
                if(this.getDoctors) {
                    this.getDoctors.forEach((d, i)=> {
                        if(d.id!==0) {
                            doctorString+= d.firstname + ' ' + d.name + (i +1 !== this.getDoctors.length ? ', ' : '')
                        }
                    })
                    return 'Prise de rendez vous en ligne avec ' + doctorString
                }
                return 'Prise de rendez vous en ligne avec'
            }
        },
        methods: {
            ...mapActions({
                setSlots: namespaces.calendar+'setSlots',
                setInitialized: namespaces.calendar+'setInitialized',
                setSelectedReason: namespaces.calendar+'setSelectedReason',
                setSelectedDoctor: namespaces.calendar+'setSelectedDoctor',
                setSelectedOffice: namespaces.calendar+'setSelectedOffice',
                setDate: namespaces.calendar+'setDate',
                setEndDate: namespaces.calendar+'setEndDate',
                setOffices: namespaces.calendar+'setOffices',
                setDoctors: namespaces.calendar+'setDoctors',
                setReasons: namespaces.calendar+'setReasons',
                setAppointments: namespaces.calendar+'setAppointments',
                setSelectedSlot: namespaces.calendar+'setSelectedSlot',
                setCurrentView: namespaces.calendar+'setCurrentView',
                setResources: namespaces.calendar+'setResources',
                setHasDoubleCalendarMsg: namespaces.calendar+'setHasDoubleCalendarMsg',
            }),
            checkLimits(currentDay, currentHour, tocompare) {
                if(
                    (Vue.$config.etablissement.days_limit > 0 && currentDay.isBefore(tocompare, 'day'))
                    || (Vue.$config.etablissement.days_limit === 0 && currentDay.isSame(moment(), 'day') && currentHour.isBefore(moment().add(Vue.$config.etablissement.hours_limit, "hour")))
                ) {
                    return true;
                }else return false;
            },
            setHiddenDays(){
                if(this.getSelectedReason) {
                    let hiddenDays = []
                    if(this.getCurrentView !== 'resourceTimeGridDay' && this.getSelectedDoctor) {
                        if(!this.getSelectedReason.opening_hours.find(oh=>oh.day_of_week === 6 && this.getSelectedDoctor.id === oh.doctor_id))
                            hiddenDays.push(6)
                        if(!this.getSelectedReason.opening_hours.find(oh=>oh.day_of_week === 7 && this.getSelectedDoctor.id === oh.doctor_id))
                            hiddenDays.push(0)
                    }else {
                        if(!this.getSelectedReason.opening_hours.find(oh=>oh.day_of_week === 6))
                            hiddenDays.push(6)
                        if(!this.getSelectedReason.opening_hours.find(oh=>oh.day_of_week === 7))
                            hiddenDays.push(0)
                    }
                    this.calendarApi.setOption('hiddenDays', hiddenDays)
                }
            },
            initRefresh() {
                if(this.refreshInterval)clearInterval(this.refreshInterval)
                if(this.getSelectedReason && this.getSelectedDoctor && this.getDate)
                    this.refreshInterval = setInterval(()=> {
                        this.loading = true
                        calendarApi.getReasons().then(res => {
                            this.setReasons(res.data.reasons)
                            this.setSelectedReason(this.getReasons.find(r=>r.id === this.getSelectedReason.id))
                            this.fetchCalendarEvents({
                                start: this.getDate,
                                end: this.getEndDate
                            }, true).then(()=> {
                                this.calendarApi.refetchEvents()
                                this.loading = false
                            })
                        })
                    }, Vue.$config.etablissement.refresh_interval * 1000 || 60000)
            },
            getCalendarSlots(date) {
                return new Promise((resolve, reject) => {
                    let slots = [];
                    let currentDay = date;
                    let view = this.calendarApi.view.type
                    let maxDays = view === 'timeGridDay' || view === 'resourceTimeGridDay' ? 1 : this.getEndDate.diff(this.getDate, 'days');
                    if(view === 'resourceTimeGridDay' && this.getSelectedReason && this.getSelectedDoctor){
                        this.getDoctors.forEach((doctor, dIndex )=> {
                            let holiday = this.isHoliday(currentDay)
                            if(holiday) {
                                slots.push({
                                    resourceId: doctor.id,
                                    id: '' + dIndex,
                                    start: currentDay.format("YYYY-MM-DD") + ' ' + "08:00:00",
                                    end: currentDay.format("YYYY-MM-DD") + ' ' + "18:00:00",
                                    extendedProps: {
                                        doctor_id: doctor.id,
                                        doctor: doctor,
                                        office: null,
                                        reason: null,
                                        holiday: true
                                    },
                                    backgroundColor: '#ffcccc',
                                    textColor: "black",
                                    title: holiday.translations[this.selectedLocale ?? 'fr']
                                });
                            }else {
                                this.getSelectedReason.opening_hours.forEach((item, index) => {
                                    let currentHour = moment(item.opening_time, "HH:mm:ss");
                                    let slotsNumber = this.getSlotsNumber(currentHour, moment(item.closing_time, "HH:mm:ss"));
                                    let tocompare = moment().add(Vue.$config.etablissement.days_limit, 'day').format('YYYY-MM-DD');
                                    for (let j = 1; j <= slotsNumber; j++) {
                                        if (
                                            currentDay.isSameOrAfter(tocompare, 'day')
                                            && item.doctor_id === doctor.id
                                            && currentDay.isSameOrBefore(moment().add(Vue.$config.etablissement.months_limit, 'months'))      //nombre de mois durant lesquels on peut prendre rendez vous
                                            && item.day_of_week === currentDay.isoWeekday()
                                            && currentHour.isSameOrAfter(moment(item.opening_time, "HH:mm:ss"))
                                            && currentHour.isSameOrBefore(moment(item.closing_time, "HH:mm:ss").subtract(this.getSelectedReason.default_duration, 'minutes'))
                                            && (Vue.$config.etablissement.office_filter ? item.office_id === this.getSelectedOffice.id || this.getSelectedOffice.id === 0 : true)
                                            && !this.checkLimits(currentDay, currentHour, tocompare)
                                        ) {

                                            let currentHourCopy = moment(currentHour);
                                            let office = this.getOffices.find(o=>o.id === item.office_id)
                                            let doctor = this.getDoctors.find(d=> d.id === item.doctor_id)
                                            slots.push({
                                                resourceId: doctor.id,
                                                start: currentDay.format("YYYY-MM-DD") + ' ' + currentHour.format("HH:mm:ss"),
                                                end: currentDay.format("YYYY-MM-DD") + ' ' + currentHourCopy.add(this.getSelectedReason.default_duration, 'minutes').format("HH:mm:ss"),
                                                backgroundColor: office.color,
                                                title: currentHour.format("HH[:]mm"),
                                                extendedProps: {
                                                    doctor_id: item.doctor_id,
                                                    doctor: {
                                                        name: doctor.name,
                                                        firstname: doctor.firstname,
                                                        accept_new_patients: doctor.accept_new_patients,
                                                        message_new_patients: doctor.message_new_patients
                                                    },
                                                    office: office,
                                                    reason: {
                                                        id : this.getSelectedReason.id,
                                                        label: this.getSelectedReason.label,
                                                        multiple_appointment: this.getSelectedReason.multiple_appointment
                                                    },
                                                    allowed: !this.checkLimits(currentDay, currentHour, tocompare),
                                                },
                                                textColor: "black"
                                            });
                                        }
                                        currentHour.add(this.getSelectedReason.default_duration, 'minutes');
                                    }
                                });
                            }

                        });
                    }else if (this.getSelectedReason && this.getSelectedDoctor){
                        for (let i = 1; i <= maxDays; i++) {
                            let holiday = this.isHoliday(currentDay)
                            if (holiday) {
                                slots.push({
                                    start: currentDay.format("YYYY-MM-DD") + ' ' + "08:00:00",
                                    end: currentDay.format("YYYY-MM-DD") + ' ' + "18:00:00",
                                    extendedProps: {
                                        office: null,
                                        reason: null,
                                        holiday: true
                                    },
                                    backgroundColor: '#ffcccc',
                                    textColor: "black",
                                    title: holiday.translations[this.selectedLocale ?? 'fr']
                                });
                            } else{
                                this.getSelectedReason.opening_hours.forEach((item, index) => {
                                    let currentHour = moment(item.opening_time, "HH:mm:ss");
                                    let slotsNumber = this.getSlotsNumber(currentHour, moment(item.closing_time, "HH:mm:ss"));
                                    let tocompare = moment().add(Vue.$config.etablissement.days_limit, 'day').format('YYYY-MM-DD');
                                    for (let j = 1; j <= slotsNumber; j++) {
                                        if (
                                            currentDay.isSameOrAfter(tocompare, 'day')
                                            && currentDay.isSameOrBefore(moment().add(Vue.$config.etablissement.months_limit, 'months'))      //nombre de mois durant lesquels on peut prendre rendez vous
                                            && item.day_of_week === currentDay.isoWeekday()
                                            && currentHour.isSameOrAfter(moment(item.opening_time, "HH:mm:ss"))
                                            && currentHour.isSameOrBefore(moment(item.closing_time, "HH:mm:ss").subtract(this.getSelectedReason.default_duration, 'minutes'))
                                            && (Vue.$config.etablissement.office_filter ? item.office_id === this.getSelectedOffice.id || this.getSelectedOffice.id === 0 : true)
                                            && !this.checkLimits(currentDay, currentHour, tocompare)
                                        ) {
                                            if (item.doctor_id === this.getSelectedDoctor.id) {
                                                let currentHourCopy = moment(currentHour);
                                                let office = this.getOffices.find(o=>o.id === item.office_id)
                                                let doctor = this.getDoctors.find(d=> d.id === item.doctor_id)
                                                slots.push({
                                                    resourceId: item.doctor_id,
                                                    start: currentDay.format("YYYY-MM-DD") + ' ' + currentHour.format("HH:mm:ss"),
                                                    end: currentDay.format("YYYY-MM-DD") + ' ' + currentHourCopy.add(this.getSelectedReason.default_duration, 'minutes').format("HH:mm:ss"),
                                                    backgroundColor: office.color,
                                                    textColor: "black",
                                                    title: currentHour.format("HH[:]mm"),
                                                    extendedProps: {
                                                        doctor_id: item.doctor_id,
                                                        doctor: {
                                                            name: doctor.name,
                                                            firstname: doctor.firstname,
                                                            accept_new_patients: doctor.accept_new_patients,
                                                            message_new_patients: doctor.message_new_patients
                                                        },
                                                        office: office,
                                                        reason: {
                                                            id : this.getSelectedReason.id,
                                                            label: this.getSelectedReason.label,
                                                            multiple_appointment: this.getSelectedReason.multiple_appointment
                                                        },
                                                        allowed: !this.checkLimits(currentDay, currentHour, tocompare),
                                                    }
                                                });
                                            }
                                        }
                                        currentHour.add(this.getSelectedReason.default_duration, 'minutes');
                                    }
                                });
                            }
                            currentDay.add(1, 'days');
                        }
                    }
                    resolve(slots);
                })
            },
            async onSearchFreeSlot() {
                this.searchingDate = true
                let data = {
                    'date': this.getDate.format('YYYY-MM-DD HH:mm:ss'),
                    'doctor_id': this.getSelectedDoctor ? this.getSelectedDoctor.id : null,
                    'reason_id': this.getSelectedReason ? this.getSelectedReason.id : null,
                    'office_id': this.getSelectedOffice ? this.getSelectedOffice.id : null,
                    'app_id': Vue.$config.app_id
                }
                try {
                    let res = await calendarApi.getFirstFreeSlotDate(data)
                    this.setDate(moment(res.data))
                    this.calendarApi.gotoDate(res.data)
                    this.searchingDate = false
                }catch(e) {
                    Vue.$toast.open({
                        message: 'Aucune date disponible',
                        type: 'info',
                    });
                    this.searchingDate = false
                }
            },
            /*
                Fonction qui compare les rendez-vous réellement pris avec les slots générés et qui les rend non disponibles si un rdv à déjà été pris dans cette plage horaire
             */
            setBusySlots(slots) {
                return new Promise((resolve, reject) => {
                    slots.forEach((slot, index) =>{
                        let nbre_appointment_allowed = slot.extendedProps.reason ? slot.extendedProps.reason.multiple_appointment : 0
                        let nbre_appointment_for_slot = 0
                        this.getAppointments.forEach((appointment) => {
                            if(
                                appointment.doctor_id != null && (this.getSelectedDoctor.id === appointment.doctor_id || this.calendarApi.view.type === 'resourceTimeGridDay')
                                && this.checkIfAppointmentExistsForSlot(appointment, slot)
                            )
                            {
                                nbre_appointment_for_slot++
                                if(appointment.user_id && this.getIsLoggedIn && parseInt(this.getUser.id) === appointment.user_id) {
                                    slot.extendedProps.allowed = false;
                                } else {
                                    if(nbre_appointment_allowed === 0 || (nbre_appointment_allowed > 0 && nbre_appointment_for_slot === nbre_appointment_allowed)) {
                                        slot.extendedProps.allowed = false;
                                    }else {
                                        slot.title = Vue.$config.etablissement.debug_mode ? 'Nbre :' + nbre_appointment_for_slot + ' / permis : ' + nbre_appointment_allowed + ' / id rdv ' + appointment.id : '';
                                    }
                                }
                            }
                        });
                    });

                    /* Pour ne pas afficher les slots qui ne sont pas disponibles*/
                    slots = slots.filter(s=> s.extendedProps.allowed !== false)
                    resolve(slots);
                })
            },
            checkIfAppointmentExistsForSlot(appointment, slot) {
                return moment(appointment.date_debut, "YYYY-MM-DD HH:mm:ss").isSame(moment(slot.start, "YYYY-MM-DD HH:mm:ss"))
                    && moment(appointment.date_fin, "YYYY-MM-DD HH:mm:ss").isSame(moment(slot.start, "YYYY-MM-DD HH:mm:ss"))
                    && appointment.doctor_id === slot.extendedProps.doctor_id
                    ||
                    (appointment.doctor_id === slot.extendedProps.doctor_id
                        && moment(slot.start, "YYYY-MM-DD HH:mm:ss")
                            .isBetween(moment(appointment.date_debut, "YYYY-MM-DD HH:mm:ss"),
                                moment(appointment.date_fin, "YYYY-MM-DD HH:mm:ss"), null, '[)'))
                    ||
                    (appointment.doctor_id === slot.extendedProps.doctor_id
                        && moment(slot.end_date, "YYYY-MM-DD HH:mm:ss")
                            .isBetween(moment(appointment.date_debut, "YYYY-MM-DD HH:mm:ss"),
                                moment(appointment.date_fin, "YYYY-MM-DD HH:mm:ss"), null, '(]'))
                    ||
                    (appointment.doctor_id === slot.extendedProps.doctor_id
                        && moment(appointment.date_debut, "YYYY-MM-DD HH:mm:ss")
                            .isBetween(moment(slot.start, "YYYY-MM-DD HH:mm:ss"),
                                moment(slot.end, "YYYY-MM-DD HH:mm:ss"), null, '()'));
            },
            isHoliday(day) {
                let holiday = false
                for (const [key, value] of Object.entries(Vue.$config.etablissement.holidays)) {
                    if(day.isSame(moment(value.date), 'day')) holiday = value
                }
                return holiday
            },
            getSlotsNumber(start, end) {
                let number = end.diff(start, 'minutes');
                return number / this.getSelectedReason.default_duration
            },
            getSlotMinOpeningHour(slots = []) {
                let minHour = moment("24:00:00", "HH:mm:ss");
                slots.forEach(slot=> {
                    let toCompare = moment(slot.start, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss');
                    if(moment(toCompare, "HH:mm:ss").isBefore(moment(minHour, 'HH:mm:ss'))) {
                        minHour = toCompare;
                    }

                })

                return minHour;
            },
            getSlotMaxOpeningHour(slots = []) {
                let maxHour = moment("00:00:00", "HH:mm:ss");
                slots.forEach(slot => {
                    let toCompare = moment(slot.end, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss');
                    if (moment(toCompare, "HH:mm:ss").isAfter(moment(maxHour, 'HH:mm:ss'))) {
                        maxHour = toCompare;
                    }
                })

                return maxHour
            },

            onEventClick(info) {
                if(info.event.extendedProps.holiday) {
                    return
                }
                if(this.config.etablissement.read_only_msg) {
                    $('#modal-read-only').modal('show')
                    return
                }
                this.setSelectedSlot(JSON.parse(JSON.stringify(info.event)))
                if(this.config.etablissement.check_double_calendar) {
                    this.setHasDoubleCalendarMsg(info.event.extendedProps.doctor_id)
                }

                if(!info.event.extendedProps.holiday) {
                    $('#confirmationModal').modal('show')

                }
            },
            onSelectDate(e) {
                this.setDate(moment(e.target.value))
                this.calendarApi.gotoDate(e.target.value)
                if(Vue.$config.etablissement.refresh){
                    this.initRefresh()
                }
            },
            onSelectDoctor(e) {
                if(e && e.id !== 0) {
                    this.setSelectedDoctor(e)
                    if(this.getCurrentView === 'resourceTimeGridDay') {
                        this.calendarApi.changeView( 'timeGridWeek')
                    }
                }
                if(e && e.id === 0) {
                    this.setSelectedDoctor(e)
                    this.calendarApi.changeView('resourceTimeGridDay')
                }
                //cas où le motif n'est pas lié au médecin
                if(Array.isArray(this.filteredReasons) && this.getSelectedReason && this.getSelectedReason.doctor_id !== this.getSelectedDoctor.id) {
                    this.setSelectedReason(this.getReasons.find(re=> re.id === this.filteredReasons.find(r=> r=true).id))
                }
                this.calendarApi.refetchEvents()
                if(Vue.$config.etablissement.refresh){
                    this.initRefresh()
                }
            },
            onSelectReason(e) {
                if(e) {
                    this.setSelectedReason(this.getReasons.find(r=>r.id ===e.id))
                    this.calendarApi.refetchEvents()
                }
                if(Vue.$config.etablissement.refresh){
                    this.initRefresh()
                }
            },
            onSelectOffice(e) {
                if(e) {
                    this.setSelectedOffice(e)
                    this.calendarApi.refetchEvents()
                }
                if(Vue.$config.etablissement.refresh){
                    this.initRefresh()
                }
            },
            async fetchCalendarEvents(info) {
                let slots = []
                let params = {
                    startDateTime: moment(info.start).format('YYYY-MM-DD HH:mm:ss'),
                    endDateTime:  moment(info.end).format('YYYY-MM-DD HH:mm:ss'),
                    app_id: this.config.etablissement.id,
                    doctor_id: this.getSelectedDoctor && this.getSelectedDoctor.id !== 0 ? this.getSelectedDoctor.id : null,
                }
                let res = await calendarApi.getFrontAppointmentsBetweenDates(params)

                this.setAppointments(res.data.appointments || []);
                this.setDate(moment(info.start))
                this.setEndDate(moment(info.end))
                this.setHiddenDays()

                slots = await this.getCalendarSlots(moment(info.start))

                slots = await this.setBusySlots(slots)
                this.setAppointments([])
                this.setMinandMaxOpenigHours(slots)
                this.calendarApi.render()
                this.slotNumber = slots.length
                return slots
            },
            setMinandMaxOpenigHours(slots = []) {
                let min = this.getSlotMinOpeningHour(slots)
                this.calendarApi.setOption('slotMinTime',min)
                let max = this.getSlotMaxOpeningHour(slots)
                this.calendarApi.setOption('slotMaxTime',max)
            },
            async init() {
                await this.setOffices()
                await this.setDoctors()
                await this.setReasons()
                await this.setResources()
                this.calendarOptions.customButtons.weekButton.text = this.$t('global.semaine')
                this.calendarOptions.customButtons.dayButton.text = this.$t('global.jour')

                // Si la vue "resource" est désactivée ou si il n'y a qu'un médecin
                if(!Vue.$config.etablissement.resource_view || this.getDoctors.length === 1) {
                    delete this.calendarOptions.customButtons.doctorsButton
                    this.calendarOptions.headerToolbar.right =  'weekButton,dayButton'
                }else {
                    this.calendarOptions.customButtons.doctorsButton.text = this.$t('global.docteurs')
                }

                if (!Vue.$config.etablissement.select_reason && !this.getSelectedReason) {
                    await this.setSelectedReason(this.getReasons.find(e => true));
                }

                if(!this.getSelectedDoctor) {
                    let params = new URLSearchParams(window.location.search);
                    let doctor_id = params.get('doctor') || this.$route.params.doctor_id;
                    //si on a un paramètre dans l'url
                    if (doctor_id) {
                        await this.setSelectedDoctor(this.getDoctors.find(d => d.id === parseInt(doctor_id)))
                        await this.setCurrentView('timeGridWeek')
                        await this.$router.push('/prise-de-rendez-vous')
                        //si l'établissement impose le choix d'un médecin
                    } else if(Vue.$config.etablissement.select_doctor) {
                        await this.setCurrentView(Vue.$config.etablissement.default_calendar_view)
                    } else if (this.getDoctors.length === 1) {
                        await this.setSelectedDoctor(this.getDoctors.find(d => true))
                        await this.setCurrentView(this.getCurrentView || 'timeGridWeek')
                    } else if(Vue.$config.etablissement.default_calendar_view !== 'resourceTimeGridDay') {
                        let defaultDoctorId = Vue.$config.etablissement.selected_doctor
                        await this.setSelectedDoctor(this.getDoctors.find(d => defaultDoctorId ? d.id === defaultDoctorId : true ))
                        await this.setCurrentView(Vue.$config.etablissement.default_calendar_view)
                    }else {
                        await this.setSelectedDoctor(this.getDoctorList.find(d => d.id === 0))
                        await this.setCurrentView('resourceTimeGridDay')
                    }
                }

                await this.setSelectedOffice(this.getOfficeList.find(o => o.id === 0));

                this.calendarApi.setOption('resources', this.getResources)

                this.calendarApi.gotoDate(this.getDate.format('YYYY-MM-DD'))

                if(this.$route.params.doctor_id && !Vue.$config.etablissement.select_reason) {
                    let doctor = this.getDoctors.find(d=>d.id === parseInt(this.$route.params.doctor_id))
                    this.onSelectDoctor(doctor)
                }else {
                    this.calendarApi.changeView(this.getCurrentView || this.calendarApi.view.type, this.getDate)
                }

                this.calendarApi.setOption('loading', (isLoading)=> {
                    this.loading = isLoading
                })
                this.calendarApi.setOption('events',(info)=> {
                    return this.fetchCalendarEvents(info).then(res=> {
                        return res
                    })
                })
                this.calendarApi.setOption('eventClick', (info)=> {
                    this.onEventClick(info)
                })
                this.calendarApi.setOption('datesSet', async (info)=> {
                    if(info.view.type !== this.getCurrentView) {
                        this.calendarApi.destroy()
                        this.calendarApi = this.$refs.fullCalendar.getApi()
                        await this.setCurrentView(info.view.type)
                        await this.calendarApi.refetchEvents()
                    }

                    await this.setCurrentView(info.view.type)
                    await this.setDate(moment(info.start))
                    await this.setEndDate(moment(info.end))
                })
                if(Vue.$config.etablissement.refresh){
                    this.initRefresh()
                }
                EventBus.$on(EventList.calendar_front.refresh, () => this.calendarApi.refetchEvents())
            }
        },
        async mounted() {
            this.officeFilter = Vue.$config.etablissement.office_filter
            this.calendarApi = this.$refs.fullCalendar.getApi()
            this.calendarApi.setOption('initialDate', this.getDate.format('YYYY-MM-DD'))
            EventBus.$on(EventList.translation.setLocale,(locale)=> {
                this.calendarApi.setOption('locale', locale)
                this.init()
            })
            await this.init()
        },
        beforeDestroy() {
            this.calendarApi.destroy()
            if(this.refreshInterval)clearInterval(this.refreshInterval)
            EventBus.$off(EventList.calendar_front.refresh)
            EventBus.$off(EventList.translation.setLocale)
        }
    }
</script>

<style scoped lang="scss">
    #spinner-wrapper {
        position: fixed;
        top:60px;
        right: 5px;
        @media(max-width: 768px) {
            position: absolute;
            top: -32px;
            left: unset;
            right: 0;
        }
    }
    #spinner {
        height: 4rem;
        width: 4rem;
        z-index: 10;
    }
    @media(max-width: 992px) {
        #spinner {
            height: 2rem;
            width: 2rem;
        }
    }

</style>
